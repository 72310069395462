import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { theme } from "../../../utils/global-styles/theme";
import BulletIcon from "../../shared/BlurredContentContainer/Assets/BulletIcon";

const WhosQualified = ({ richText }) => {
  const Heading2 = styled.h2`
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      line-height: 64px;
      margin-top: 40px;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 36px;
        letter-spacing: -0.94px;
        line-height: 28px;
      }
    `,
    Paragraph = styled.p`
    color: ${theme.colors.main};
    font-family: ${theme.fontFaces.paragraphs}
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.45px;
    line-height: 32px;
    padding-left: 22px;
    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 18px;
      letter-spacing: -0.39px;
      line-height: 26px;
    }
  `,
    ListItem = styled.div`
      display: flex;
      flex-direction: row;
      margin-top: 25px;
    `,
    BulletIconContainer = styled.div`
      height: max-content;
      width: max-content;
      padding-top: 4px;
    `,
    Wrapper = styled.div`
      margin-left: 325px;
      margin-right: 325px;
      margin-top: 120px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-left: 120px;
        margin-right: 120px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-top: 60px;
        margin-left: 14px;
        margin-right: 14px;
      }
    `,
    richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: node => {
          return node && node.props ? <b>{node}</b> : <b>{node}</b>;
        },
        [MARKS.ITALIC]: node => {
          return node && node.props ? <i>{node.props}</i> : <i>{node}</i>;
        },
        [MARKS.UNDERLINE]: node => {
          return node && node.props ? (
            <u>{node.props.children}</u>
          ) : (
            <u>{node}</u>
          );
        },
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <a className={"primary"} href={node.data.uri}>
            {children}
          </a>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading2 className="primary">{children}</Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <ListItem>
            <BulletIconContainer>
              <BulletIcon />
            </BulletIconContainer>
            {children}
          </ListItem>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return children && children[0] !== "" ? (
            <Paragraph>{children}</Paragraph>
          ) : null;
        },
      },
    };
  const richTextNodes = JSON.parse(richText).content;
  return (
    <Wrapper>
      {richTextNodes.map((item, index) => {
        return documentToReactComponents(item, richTextOptions);
      })}
    </Wrapper>
  );
};

WhosQualified.propTypes = {
  richText: PropTypes.object,
};

export default WhosQualified;
