import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import { Swipeable } from "react-swipeable";

import { theme } from "../../../utils/global-styles/theme";

import Bubble from "../../BubbleSlider/child-components/Bubble";

const PastParticipants = ({ contentMultiple, heading, logoArray }) => {
  const logosArrayLength = logoArray ? logoArray.length : null,
    [currentStartIndex, setCurrentStartIndex] = useState(0),
    [activeBubbleIndex, setActiveBubbleIndex] = useState(0),
    [logosRemaining, setLogosRemaining] = useState(logosArrayLength || 0),
    manuallyChangeSlide = index => {
      setCurrentStartIndex(index * contentMultiple);
      setActiveBubbleIndex(index);
      setLogosRemaining(logosArrayLength - index * contentMultiple);
    },
    buildBubbles = (totalCount, activeIndex, setActive) => {
      const bubbles = [];
      for (let i = 0; i < totalCount; i++) {
        bubbles.push(
          <Bubble
            active={activeIndex === i}
            setActive={setActive}
            index={i}
            key={i}
            fillColor="primary-fill"
            strokeColor="primary-stroke"
          />
        );
      }
      return bubbles;
    };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (logosRemaining > contentMultiple) {
        setCurrentStartIndex(currentStartIndex + contentMultiple);
        setActiveBubbleIndex(activeBubbleIndex + 1);
        setLogosRemaining(logosRemaining - contentMultiple);
      } else if (logosRemaining <= contentMultiple && logosRemaining > 0) {
        setCurrentStartIndex(0);
        setActiveBubbleIndex(0);
        setLogosRemaining(logosArrayLength);
      }
    }, 5000);
    return () => {
      clearTimeout(timerId);
    };
  });

  const PastParticipantsGrid = styled(Swipeable)`
      font-family: ${theme.fontFaces.headers};
      display: grid;
      grid-template: auto auto 80px / 1fr 1440px 1fr;
      margin-top: 120px;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-template: auto auto 80px / 1fr;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-top: 54px;
      }
    `,
    GrayBackground = styled.div`
      grid-area: 2 / 1 / span 2 / span 3;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 2 / 1 / span 2 / span 1;
      }
    `,
    SectionHeadingContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      padding-left: 74px;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 1 / 1 / span 1 / span 1;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-left: 10px;
      }
    `,
    SectionHeading = styled.h1`
      font-size: 130px;
      font-weight: bold;
      letter-spacing: -6.25px;
      line-height: 132px;
      margin-bottom: -45px;
      max-width: 880px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 100px;
        font-weight: bold;
        letter-spacing: -4.25px;
        line-height: 110px;
        margin-bottom: -38px;
        max-width: 680px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 100px;
        letter-spacing: -4.25px;
        line-height: 110px;
        margin-bottom: -38px;
        max-width: 680px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 40px;
        letter-spacing: -1.92px;
        line-height: 48px;
        margin-bottom: -17.5px;
        max-width: 300px;
      }
    `,
    LogosContainer = styled.div`
      grid-area: 2 / 2 / span 1 / span 1;
      padding: 82px 76px 0 80px;
      display: flex;
      flex-wrap: wrap;

      -webkit-animation: fadein 2s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s ease-in-out; /* Firefox < 16 */
      -ms-animation: fadein 2s ease-in-out; /* Internet Explorer */
      -o-animation: fadein 2s ease-in-out; /* Opera < 12.1 */
      animation: fadein 2s ease-in-out;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 2 / 1 / span 1 / span 1;
      }
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        justify-content: center;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 34px 10px 0 10px;
        justify-content: flex-start;
      }
    `,
    LogoContainer = styled.div`
      height: 300px;
      width: 300px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        height: 167px;
        width: 167px;
        margin: 5px;
      }
    `,
    PaginationDotsContainer = styled.div`
      grid-area: 3 / 2 / span 1 / span 1;
      display: flex;
      justify-content: center;
      padding: 20px 0 40px 0;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 3 / 1 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 24px 0 30px 0;
      }
    `;

  return (
    <PastParticipantsGrid
      onSwipedLeft={() => manuallyChangeSlide(activeBubbleIndex + 1)}
      onSwipedRight={() => manuallyChangeSlide(activeBubbleIndex - 1)}
    >
      <GrayBackground className="light-bg"></GrayBackground>
      <SectionHeadingContainer>
        <SectionHeading>{heading || "Past Participants"}</SectionHeading>
      </SectionHeadingContainer>
      <LogosContainer>
        {logoArray
          .filter(
            (paticipantLogo, index) =>
              index >= currentStartIndex &&
              index < currentStartIndex + contentMultiple
          )
          .map((participantLogo, index) => {
            return (
              <LogoContainer className="white-bg" key={index}>
                {participantLogo.image && participantLogo.image.fluid && (
                  <Img
                    fluid={participantLogo.image.fluid}
                    css={css`
                      height: 264px / ${participantLogo.image.fluid.aspectRatio};
                      width: 264px;

                      @media only screen and (max-width: ${theme.breakpoints
                          .helper800}) {
                        height: 90% / ${participantLogo.image.fluid.aspectRatio};
                        width: 90%;
                      }
                    `}
                  />
                )}
              </LogoContainer>
            );
          })}
      </LogosContainer>
      <PaginationDotsContainer>
        {buildBubbles(
          logosArrayLength / contentMultiple,
          activeBubbleIndex,
          manuallyChangeSlide
        )}
      </PaginationDotsContainer>
    </PastParticipantsGrid>
  );
};

PastParticipants.propTypes = {
  contentMultiple: PropTypes.number,
  heading: PropTypes.string,
  logoArray: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        aspectRatio: PropTypes.number,
        srcSet: PropTypes.string,
      }),
    })
  ),
};

export default PastParticipants;
