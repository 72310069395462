import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

const KeyDates = ({ dates }) => {
  const KeyDatesGrid = styled.div`
      display: grid;
      grid-template: auto 227px / auto;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: auto auto / auto;
      }
    `,
    HeadingContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 3;
      z-index: 1;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-area: 1 / 1 / span 1 / span 1;
      }
    `,
    Heading = styled.h1`
      font-size: 132px;
      line-height: 132px;
      margin-left: 108px;
      margin-bottom: -40px;
      z-index: 1;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 80px;
        margin-left: 14px;
        margin-bottom: -55px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 14px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: -1.92px;
        line-height: 48px;
        margin-bottom: -18px;
      }
    `,
    DatesSubGrid = styled.div`
      display: grid;
      grid-template: auto / auto auto auto;
      grid-area: 2 / 1 / span 1 / span 3;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template: auto auto auto / auto;
        grid-area: 2 / 1 / span 3 / span 1;
        padding-top: 34px;
        padding-bottom: 51px;
      }
    `,
    DateWrapper = styled.div`
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-self: center;
      > h6 {
        padding-bottom: 20px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        align-self: flex-start;
        justify-self: flex-start;
        margin-left: 14px;
        > h6 {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    `;
  return (
    <KeyDatesGrid>
      <HeadingContainer>
        <Heading>Key Dates</Heading>
      </HeadingContainer>
      <DatesSubGrid className="light-bg">
        {dates &&
          dates.map((date, index) => {
            return (
              <DateWrapper
                css={css`
                  grid-area: 1 / ${index + 1} / span 1 / span 1;
                  @media only screen and (max-width: ${theme.breakpoints
                      .helper1100}) {
                    grid-area: ${index + 2} / 1 / span 1 / span 1;
                  }
                `}
              >
                <h6>{date.title}</h6>
                <h2 className="primary">{date.description.description}</h2>
              </DateWrapper>
            );
          })}
      </DatesSubGrid>
    </KeyDatesGrid>
  );
};

KeyDates.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default KeyDates;
