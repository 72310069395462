import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

import { theme } from "../../../utils/global-styles/theme";

const CheckMarkIcon = ({ iconColorClassName, height, width, marginBottom }) => {
  return (
    <svg
      width={width || "18px"}
      height={height || "14px"}
      viewBox="0 0 22 16"
      version="1.1"
      css={css`
        margin-bottom: ${marginBottom};
      `}
    >
      <g
        id="icon/check"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
        <rect
          id="Rectangle"
          fill={theme.colors.main}
          transform="translate(7.353553, 11.646447) rotate(-315.000000) translate(-7.353553, -11.646447) "
          x="3.35355339"
          y="10.1464466"
          width="8"
          height="3"
          className={iconColorClassName}
        ></rect>
        <rect
          id="Rectangle-Copy"
          fill={theme.colors.main}
          transform="translate(13.000000, 9.500000) rotate(-225.000000) translate(-13.000000, -9.500000) "
          x="7"
          y="8"
          width="12"
          height="3"
          className={iconColorClassName}
        ></rect>
      </g>
    </svg>
  );
};

CheckMarkIcon.propTypes = {
  iconColorClassName: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CheckMarkIcon;
