import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";

import { theme } from "../../../utils/global-styles/theme";
import BulletIcon from "../../shared/BlurredContentContainer/Assets/BulletIcon";

const Schedule = ({ scheduleData }) => {
  const Wrapper = styled.div`
      margin-left: 225px;
      margin-right: 225px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-left: 120px;
        margin-right: 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 14px;
        margin-right: 14px;
      }
    `,
    ScheduleHeader = styled.h4`
      margin-bottom: 50px;
      margin-top: 120px;
      margin-left: 108px;
      color: ${theme.colors.main};
      font-size: 130px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        margin-left: 50px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 13px;
        margin-bottom: 15px;
        font-size: 40px;
        margin-top: 70px;
      }
    `,
    WeekTitle = styled.h6`
      color: ${theme.colors.main};
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    `;
  return (
    <>
      <ScheduleHeader>Schedule</ScheduleHeader>
      <Wrapper>
        {scheduleData.map((scheduleItem, index) => {
          return (
            <>
              <WeekTitle>{scheduleItem.title}</WeekTitle>
              <Markdown className="schedule">
                {scheduleItem.description.description}
              </Markdown>
            </>
          );
        })}
      </Wrapper>
    </>
  );
};

Schedule.propTypes = {
  scheduleData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      title: PropTypes.string,
    })
  ),
};

export default Schedule;
